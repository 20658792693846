import Spoiler from 'components/basics/Spoiler';
import { MomentPartsFragment } from 'generated/graphql';
import { RichTextViewer } from 'ui/specific/richText/RichTextViewer';
import getGradient from 'utils/getGradient';
import styles from './Content.module.scss';

type Props = {
  moment: MomentPartsFragment;
  gradientColors?: string[] | null;
};

const Content = ({ moment, gradientColors }: Props): JSX.Element => {
  const gradientStyle = {
    ['--gradient' as never]: getGradient(gradientColors),
  };

  return (
    <>
      <Spoiler text={'highlight'} hideIt={moment.spoiler}>
        {moment.quote && (
          <div className={styles.quote} style={gradientStyle}>
            <RichTextViewer content={moment.quoteJson ? moment.quoteJson : moment.quote} />
            {moment.where && <div className={styles.page}>Page {moment.where}</div>}
          </div>
        )}
        {moment.note && (
          <div className={styles.note}>
            <RichTextViewer content={moment.noteJson ? moment.noteJson : moment.note} />
          </div>
        )}
      </Spoiler>
    </>
  );
};

export default Content;

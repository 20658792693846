export type LabelValue = { value: string; label: string };

export const tags = [
  { value: 'remarkable', label: 'Remarkable' },
  { value: 'addictive', label: 'Addictive' },
  { value: 'magnetic', label: 'Magnetic' },
  { value: 'page turning', label: 'Page turning' },
  { value: 'fascinating', label: 'Fascinating' },
  { value: 'awe-inspiring', label: 'Awe-inspiring' },
  { value: 'extraordinary', label: 'Extraordinary' },
  { value: 'delightful', label: 'Delightful' },
  { value: 'beautiful', label: 'Beautiful' },
  { value: 'sophisticated', label: 'Sophisticated' },
  { value: 'eloquent', label: 'Eloquent' },
  { value: 'powerful', label: 'Powerful' },
  { value: 'ambitious', label: 'Ambitious' },
  { value: 'bold', label: 'Bold' },
  { value: 'daring', label: 'Daring' },
  { value: 'charming', label: 'Charming' },
  { value: 'compelling', label: 'Compelling' },
  { value: 'vivid', label: 'Vivid' },
  { value: 'conceptual', label: 'Conceptual' },
  { value: 'complex', label: 'Complex' },
  { value: 'layered', label: 'Layered' },
  { value: 'cerebral', label: 'Cerebral' },
  { value: 'contemplative', label: 'Contemplative' },
  { value: 'intelligent', label: 'Intelligent' },
  { value: 'clever', label: 'Clever' },
  { value: 'creative', label: 'Creative' },
  { value: 'inventive', label: 'Inventive' },
  { value: 'artistic', label: 'Artistic' },
  { value: 'avant-garde', label: 'Avant-garde' },
  { value: 'surreal', label: 'Surreal' },
  { value: 'dark', label: 'Dark' },
  { value: 'deceptive', label: 'Deceptive' },
  { value: 'deep', label: 'Deep' },
  { value: 'easy read', label: 'Easy read' },
  { value: 'edgy', label: 'Edgy' },
  { value: 'educational', label: 'Educational' },
  { value: 'thought provoking', label: 'Thought provoking' },
  { value: 'controversial', label: 'Controversial' },
  { value: 'emotional', label: 'Emotional' },
  { value: 'touching', label: 'Touching' },
  { value: 'enigmatic', label: 'Enigmatic' },
  { value: 'hilarious', label: 'Hilarious' },
  { value: 'witty', label: 'Witty' },
  { value: 'visionary', label: 'Visionary' },
  { value: 'fast paced', label: 'Fast paced' },
  { value: 'erratic', label: 'Erratic' },
  { value: 'exciting', label: 'Exciting' },
  { value: 'suspenseful', label: 'Suspenseful' },
  { value: 'thrilling', label: 'Thrilling' },
  { value: 'expressive', label: 'Expressive' },
  { value: 'convincing', label: 'Convincing' },
  { value: 'feel good', label: 'Feel good' },
  { value: 'goofy', label: 'Goofy' },
  { value: 'playful', label: 'Playful' },
  { value: 'gross', label: 'Gross' },
  { value: 'gruesome', label: 'Gruesome' },
  { value: 'light hearted', label: 'Light hearted' },
  { value: 'comforting', label: 'Comforting' },
  { value: 'heartwarming', label: 'Heartwarming' },
  { value: 'heartbreaking', label: 'Heartbreaking' },
  { value: 'tragic', label: 'Tragic' },
  { value: 'insightful', label: 'Insightful' },
  { value: 'inspirational', label: 'Inspirational' },
  { value: 'dizzying', label: 'Dizzying' },
  { value: 'intense', label: 'Intense' },
  { value: 'meaningful', label: 'Meaningful' },
  { value: 'profound', label: 'Profound' },
  { value: 'paradoxical', label: 'Paradoxical' },
  { value: 'offbeat', label: 'Offbeat' },
  { value: 'unforgettable', label: 'Unforgettable' },
  { value: 'original', label: 'Original' },
  { value: 'refreshing', label: 'Refreshing' },
  { value: 'unique', label: 'Unique' },
  { value: 'ironic', label: 'Ironic' },
  { value: 'pure', label: 'Pure' },
  { value: 'honest', label: 'Honest' },
  { value: 'candid', label: 'Candid' },
  { value: 'sweet', label: 'Sweet' },
  { value: 'simple', label: 'Simple' },
  { value: 'surprising', label: 'Surprising' },
  { value: 'unpredictable', label: 'Unpredictable' },
  { value: 'timeless', label: 'Timeless' },
  { value: 'vibrant', label: 'Vibrant' },
  { value: 'implausible', label: 'Implausible' },
  { value: 'obnoxious', label: 'Obnoxious' },
  { value: 'predictable', label: 'Predictable' },
  { value: 'repetitive', label: 'Repetitive' },
  { value: 'long winded', label: 'Long winded' },
  { value: 'pretentious', label: 'Pretentious' },
  { value: 'testosterone-y', label: 'Testosterone-y' },
  { value: 'dumb', label: 'Dumb' },
  { value: 'shallow', label: 'Shallow' },
  { value: 'depressing', label: 'Depressing' },
  { value: 'offensive', label: 'Offensive' },
  { value: 'dry', label: 'Dry' },
  { value: 'unoriginal', label: 'Unoriginal' },
];

export function getTagsIn(values: string[]): LabelValue[] {
  return tags.filter((t) => values.includes(t.value));
}

export function getRandomTags(n: number, except: string[] = []): LabelValue[] {
  const shuffled = tags.filter((t) => !except.includes(t.value)).sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
}

import { ReactNode, useState } from 'react';
import { Button } from 'ui/generic';
import styles from './Spoiler.module.scss';

type Props = {
  hideIt?: boolean;
  children: ReactNode;
  text?: string;
};

const Spoiler = ({ hideIt, children, text }: Props): JSX.Element => {
  const [hide, setHide] = useState(hideIt);

  return (
    <div className={`${styles.container} ${hide ? styles.expandForWarning : ''}`}>
      <div className={`${styles.inner} ${hide ? styles.spoiler : ''}`}>{children}</div>
      {hide && (
        <div className={styles.spoilerWarning}>
          <span className={styles.spoilerText}>This {text} contains a spoiler</span>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setHide(false);
            }}
          >
            See it anyway
          </Button>
        </div>
      )}
    </div>
  );
};

export default Spoiler;

import classnames from 'classnames';
import { BrandMark } from 'components/layout/shared/Brand';
import { BookPartsFragment, MomentPartsFragment } from 'generated/graphql';
import { ShareFormat, useGetShareFormat } from 'hooks/useGetShareFormat';
import { Cover } from 'ui/generic';
import getGradient from 'utils/getGradient';
import { notEmpty } from 'utils/typeGuards';
import styles from './MomentShare.module.scss';

type Props = {
  moment: MomentPartsFragment & {
    book?: BookPartsFragment;
  };
  shareFormat?: ShareFormat;
};

const MomentShare = ({ moment, shareFormat }: Props): JSX.Element => {
  const sharingFormat = useGetShareFormat(shareFormat);

  const style = {
    ['--gradient' as never]: getGradient(moment.book?.gradientColors?.filter(notEmpty)),
  };
  return (
    <div
      className={classnames(styles.container, {
        [styles.square]: sharingFormat === 'square',
        [styles.portrait]: sharingFormat === 'portrait',
      })}
      style={style}
    >
      {moment.book && sharingFormat === 'landscape' && (
        <div className={styles.image}>
          <div className={styles.logo}>
            <BrandMark />
          </div>
          <div className={styles.cover}>
            <Cover book={moment.book} stretch="vertical" assetSize="600" />
          </div>
        </div>
      )}

      <div className={styles.info}>
        {sharingFormat === 'portrait' && (
          <div className={styles.logo}>
            <BrandMark />
          </div>
        )}
        <div className={styles.inner}>
          {moment.quote && <div className={styles.quote}>{moment.quote}</div>}
          {moment.note && <div className={styles.note}>{moment.note}</div>}
        </div>
      </div>
      {moment.book && sharingFormat !== 'landscape' && (
        <div className={styles.bookBar}>
          <div className={styles.cover}>
            <Cover book={moment.book} assetSize="600" />
          </div>
          <div className={styles.bookInfo}>
            <div className={styles.title}>{moment.book.title}</div>
            <div className={styles.authors}>
              {moment.book.authors.map((author) => author.name).join(', ')}
            </div>
          </div>
          <div className={styles.logoSquare}>
            <BrandMark />
          </div>
        </div>
      )}
    </div>
  );
};

export default MomentShare;

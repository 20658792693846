import OptionsMenu from 'components/book/options/OptionsMenu';
import OptionsButton from 'components/reaction/OptionsButton';
import {
  BookPartsFragment,
  ContentFlagTarget,
  ProfilePartsFragment,
  ReviewPartsFragment,
  useDeleteReviewMutation,
} from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { ReactNode, useMemo } from 'react';
import { FlagIcon, PenIcon, RemoveIcon } from 'ui/icons';
import { ActionItem } from 'ui/specific/actionList';

type ReviewOptionsItems = {
  profile: ProfilePartsFragment;
  book: BookPartsFragment;
  review: ReviewPartsFragment;
  closeDropdown?: () => void;
};

export const ReviewMenuOptionsItems = ({
  profile,
  book,
  review,
  closeDropdown,
}: ReviewOptionsItems): JSX.Element | null => {
  const { profile: currentProfile } = useAuthContext();
  const { setContentFlagModal, setReviewModal } = useGlobalModalContext();
  const [deleteReview] = useDeleteReviewMutation();

  const isMyReview = useMemo(() => {
    return currentProfile && currentProfile.id === profile.id;
  }, [currentProfile, profile]);

  return (
    <>
      {isMyReview && (
        <>
          <ActionItem
            item={{
              title: 'Edit review',
              onClick: () => {
                setReviewModal({ book, reloadOnChange: true });
                closeDropdown?.();
              },
              icon: <PenIcon />,
            }}
          />
          <ActionItem
            item={{
              title: 'Delete review',
              onClick: async (_, e) => {
                const areYouSure = confirm(`Are you sure you want to delete your review?`);
                if (areYouSure) {
                  e.preventDefault();
                  deleteReview({
                    variables: { reviewId: review.id },
                    update: (store) => {
                      const myReview = { __typename: 'Review', id: review.id };
                      store.evict({ id: store.identify(myReview) });
                    },
                  });
                }
              },
              icon: <RemoveIcon />,
            }}
          />
          <ActionItem item={{ divider: 'full' }} />
        </>
      )}

      <ActionItem
        item={{
          title: 'Report',
          icon: <FlagIcon />,
          onClick: () =>
            setContentFlagModal({
              targetId: review.id,
              targetType: ContentFlagTarget.Review,
              snapshot: JSON.stringify(review),
            }),
        }}
      />
      <ActionItem item={{ divider: 'full' }} />
    </>
  );
};

type Props = {
  review: ReviewPartsFragment;
  profile: ProfilePartsFragment;
  book: BookPartsFragment;
  positioningStrategy?: 'absolute' | 'fixed';
  noScroll?: boolean;
  additionalOptions?: ReactNode;
};

export const ReviewMenuOptions = ({
  review,
  profile,
  book,
  positioningStrategy,
  additionalOptions = null,
}: Props): JSX.Element => {
  return (
    <OptionsMenu
      button={
        <div>
          <OptionsButton />
        </div>
      }
      positioningStrategy={positioningStrategy}
      noScroll
    >
      <ReviewMenuOptionsItems review={review} book={book} profile={profile} />
      {additionalOptions && (
        <>
          <ActionItem item={{ divider: 'full' }} />
          {additionalOptions}
        </>
      )}
    </OptionsMenu>
  );
};

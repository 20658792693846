import { notEmpty } from './typeGuards';

/**
 * @param  {string} string Returns a gradient based on the string input. The same string will always get the same gradient.
 */
const getGradient = (gradientColors?: string[] | null): string => {
  if (!gradientColors || gradientColors.length !== 3) return 'var(--uiOutline)';
  return `linear-gradient(175.27deg, ${gradientColors[0]} -1.64%, ${gradientColors[1]} 59.08%, ${gradientColors[2]} 85.36%)`;
};

export const getGradientStyle = (colors: (string | null)[] | null | undefined): Record<string, string> => {
  return {
    ['--gradient' as string]: getGradient(colors?.filter(notEmpty)),
  };
};

export default getGradient;

import OptionsMenu from 'components/book/options/OptionsMenu';
import OptionsButton from 'components/reaction/OptionsButton';
import {
  BookPartsFragment,
  ContentFlagTarget,
  MomentPartsFragment,
  ProfilePartsFragment,
  useDeleteMomentMutation,
} from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { ReactNode } from 'react';
import { FlagIcon, PenIcon, RemoveIcon } from 'ui/icons';
import { ActionItem } from 'ui/specific/actionList';

type MomentOptionsItems = {
  moment: MomentPartsFragment;
  profile: ProfilePartsFragment;
  book: BookPartsFragment;
  closeDropdown?: () => void;
};

export const MomentMenuOptionsItems = ({
  moment,
  profile,
  book,
  closeDropdown,
}: MomentOptionsItems): JSX.Element | null => {
  const { profile: currentProfile } = useAuthContext();
  const { setMomentModal, setContentFlagModal } = useGlobalModalContext();
  const [deleteMomentMutation] = useDeleteMomentMutation();

  const isMyMoment = currentProfile && currentProfile.id === profile.id;

  return (
    <>
      {isMyMoment && (
        <>
          <ActionItem
            item={{
              title: 'Edit highlight',
              onClick: () => {
                setMomentModal({ moment, book, reloadOnChange: true });
                {
                  closeDropdown && closeDropdown();
                }
              },
              icon: <PenIcon />,
            }}
          />
          <ActionItem
            item={{
              icon: <RemoveIcon />,
              title: 'Delete highlight',
              onClick: () => {
                const areYouSure = confirm('Are you sure you want to delete this highlight?');
                if (areYouSure) {
                  deleteMomentMutation({
                    variables: {
                      momentId: moment.id,
                    },
                    update: (store) => {
                      const myMoment = {
                        __typename: 'Moment',
                        id: moment.id,
                      };
                      store.evict({
                        id: store.identify(myMoment),
                      });
                      store.evict({ id: 'ROOT_QUERY', fieldName: 'momentsCounts' });
                    },
                  });
                }
              },
            }}
          />
          <ActionItem item={{ divider: 'full' }} />
        </>
      )}

      <ActionItem
        item={{
          title: 'Report',
          icon: <FlagIcon />,
          onClick: () =>
            setContentFlagModal({
              targetId: moment.id,
              targetType: ContentFlagTarget.Moment,
              snapshot: JSON.stringify(moment),
            }),
        }}
      />
    </>
  );
};
type Props = {
  moment: MomentPartsFragment;
  profile: ProfilePartsFragment;
  book: BookPartsFragment;
  positioningStrategy?: 'absolute' | 'fixed';
  noScroll?: boolean;
  additionalOptions?: ReactNode;
};

export const MomentMenuOptions = ({
  moment,
  profile,
  book,
  positioningStrategy,
  additionalOptions = null,
}: Props): JSX.Element => {
  return (
    <OptionsMenu
      button={
        <div>
          <OptionsButton />
        </div>
      }
      positioningStrategy={positioningStrategy}
      noScroll
    >
      <MomentMenuOptionsItems moment={moment} book={book} profile={profile} />
      {additionalOptions && (
        <>
          <ActionItem item={{ divider: 'full' }} />
          {additionalOptions}
        </>
      )}
    </OptionsMenu>
  );
};
